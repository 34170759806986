import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DatePipe } from '@angular/common';
import { AtoBtransfer, AtoBtransfer_V1 } from '../../models/kpi-models';
import { DataService } from 'src/app/service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-atob-transfer-preview',
  templateUrl: './atob-transfer-preview.component.html',
  styleUrls: ['./atob-transfer-preview.component.css']
})
export class AtobTransferPreviewComponent implements OnInit {

  deviceType: any;
  device: any;
  userType: any;
  userId: any;
  searchText = '';
  isLongPress: boolean = false;
  isSelected: boolean = false;
  list: any;
  outletSelectedB: any;
  outletSelectedA: any;
  visicColerForm: any;
  coolerPhoto: any;
  outletPhoto: any;
  outletIDPhoto: any;
  pagePhoto1: any;
  pagePhoto2: any;
  atobtransferObj: any;
  // public atobtransfer: AtoBtransfer = new AtoBtransfer();
  public atobtransfer: AtoBtransfer_V1 = new AtoBtransfer_V1()
  selected: string | null = "";
  filename: any;
  token: any;
  formname: any;
  actiondesc: any;
  FieldName: any;
  FieldValue: any;
  lat: any = ""
  lng: any = ""
  selectedOutletDetailAtobVisicoolerValue:any=""
  constructor(private activatedRoute: ActivatedRoute, private ds: DataService, private cs: CommonUtilitiesService,private toaster:ToastrService,
    private router: Router, private headerService: HeaderService, public notifyMeService: NotifyMeService, private datePipe: DatePipe, private mds: MongoDataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('A to B Transfer Preview');
    this.headerService.setActiveURl('/atobTransferPreview');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userId = localStorage.getItem("UserID");
    this.userType = localStorage.getItem("UserType");
    this.token = localStorage.getItem("TokenNo");
    this.outletSelectedB = JSON.parse(localStorage.getItem("outletSelectedB") || '{}');
    this.outletSelectedA = JSON.parse(localStorage.getItem("outletSelectedA") || '{}');
    this.visicColerForm = JSON.parse(localStorage.getItem("visicColerForm") || '{}');
    this.selectedOutletDetailAtobVisicoolerValue=localStorage.getItem("selectedOutletDetailAtobVisicoolerValue")
    this.FieldName = localStorage.getItem("FieldName");
    this.FieldValue = localStorage.getItem("FieldValue");
    this.formname = "atobTransferPreview";
    this.actiondesc = "atob Transfer Preview"
    this.GetGeoLocation()
    // this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.FieldName, this.FieldValue);
    this.AToBImagesDownload(this.outletSelectedB.outletcode + '_1.jpg');
    this.AToBImagesDownload(this.outletSelectedB.outletcode + '_2.jpg');
    this.AToBImagesDownload(this.outletSelectedB.outletcode + '_3.jpg');
    this.AToBImagesDownload(this.outletSelectedB.outletcode + '_7.jpg');
    this.AToBImagesDownload(this.outletSelectedB.outletcode + '_8.jpg');
    this.AToBImagesDownload(this.outletSelectedB.outletcode + '_1.JPEG');
    this.AToBImagesDownload(this.outletSelectedB.outletcode + '_2.JPEG');
    this.AToBImagesDownload(this.outletSelectedB.outletcode + '_3.JPEG');
    this.AToBImagesDownload(this.outletSelectedB.outletcode + '_7.JPEG');
    this.AToBImagesDownload(this.outletSelectedB.outletcode + '_8.JPEG');

    // this.coolerPhoto = JSON.parse(localStorage.getItem("CoolerPhoto") || '{}');
    // this.outletPhoto = JSON.parse(localStorage.getItem("OutletPhoto") || '{}');
    // this.outletIDPhoto = JSON.parse(localStorage.getItem("OutletIDPhoto") || '{}');
    // this.pagePhoto1 = JSON.parse(localStorage.getItem("PagePhoto1") || '{}');
    // this.pagePhoto2 = JSON.parse(localStorage.getItem("PagePhoto2") || '{}');
    this.atobtransferObj = JSON.parse(localStorage.getItem("atobtransferObj") || '{}');
    // this.selected = this.activatedRoute.snapshot.queryParams["selected"];
    this.selected = localStorage.getItem("selected");
    console.log("selected", this.selected);
    console.log("outletSelectedA", this.outletSelectedA);
    console.log("outletSelectedB", this.outletSelectedB);
    console.log("visicColerForm", this.visicColerForm);
    console.log("atobtransferObj", this.atobtransferObj);
    if (this.selected == 'VS') {
      this.searchOutlet(this.outletSelectedA.outletcode, 'ATOB');
    }
  }

  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, 
      this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }


  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService?.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService?.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService?.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }

  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.userId,
      "usertype": this.userType,
      "formname": this.formname,
      "actiondesc": this.actiondesc,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  onLongPress() {
    this.isLongPress = true;
  }

  clickOnSelect() {
    this.isSelected = true;
  }

  rowClick() {
    debugger
    if (this.lat == "" || this.lat == undefined || this.lng == "" || this.lng == undefined ) {
      this.toaster.warning("Please Allow Location First")
      this.GetGeoLocation()
      return;
    }
    if (this.selected == 'VS') {
      this.atobtransfer.UserID = this.userId;
      this.atobtransfer.FromOutletCode = this.outletSelectedA.outletcode.toString();
      this.atobtransfer.ToOutletCode = this.outletSelectedB.outletcode.toString();
      this.atobtransfer.FromRDCode = this.outletSelectedA.rdCode.toString();
      this.atobtransfer.ToRDCode = this.outletSelectedB.rdcode.toString();
      // this.atobtransfer.FromVisiCooler1 = this.outletSelectedA.visicoolertype.toString();
      this.atobtransfer.FromVisiCooler1=this.selectedOutletDetailAtobVisicoolerValue
      this.atobtransfer.ToVisiCooler1 = this.outletSelectedB.visicooler.toString();
      this.atobtransfer.Flag = "SAVE";
      this.atobtransfer.ID = 0;
      this.atobtransfer.MobileNo = this.visicColerForm.mobNo.toString();
      this.atobtransfer.Add1 = this.visicColerForm.address1.toString();
      this.atobtransfer.Add2 = this.visicColerForm.address2.toString();
      this.atobtransfer.Landmark = this.visicColerForm.landmark.toString();
      this.atobtransfer.PinCode = this.visicColerForm.pinCode.toString();
      this.atobtransfer.Visiid = this.list[0].visiID.toString();
      this.atobtransfer.CoolerPhoto = this.atobtransferObj.CoolerPhoto == null ? '' : this.atobtransferObj.CoolerPhoto;
      this.atobtransfer.OutletPhoto = this.atobtransferObj.OutletPhoto == null ? '' : this.atobtransferObj.OutletPhoto;
      this.atobtransfer.OutletIDPhoto = this.atobtransferObj.OutletIDPhoto == null ? '' : this.atobtransferObj.OutletIDPhoto;
      this.atobtransfer.Page1Photo = this.atobtransferObj.Page1Photo == null ? '' : this.atobtransferObj.Page1Photo;
      this.atobtransfer.Page2Photo = this.atobtransferObj.Page2Photo == null ? '' : this.atobtransferObj.Page2Photo;
      this.atobtransfer.FIRID = this.outletSelectedA.firid == null ? 0 : this.outletSelectedA.firid;
      this.atobtransfer.EmailID = this.visicColerForm["EMailID"].toString();
      this.atobtransfer.AssetCode = this.visicColerForm["AssetCOde"].toString();
      this.atobtransfer.SerialNo = this.visicColerForm["SerialNo"].toString();
      this.atobtransfer.keyid = localStorage.getItem("keyID");
      this.atobtransfer.Outletname = this.outletSelectedB.outletname.toString();

      // new
      this.atobtransfer.RequestRaiseBy = "PWA";
      this.atobtransfer.Lat = this.lat?.toString();
      this.atobtransfer.Long = this.lng?.toString()


    } else {
      this.atobtransfer.UserID = this.userId;
      this.atobtransfer.FromOutletCode = this.outletSelectedA.outletcode.toString();
      this.atobtransfer.ToOutletCode = this.outletSelectedB.outletcode.toString();
      this.atobtransfer.FromRDCode = this.outletSelectedA.rdcode.toString();
      this.atobtransfer.ToRDCode = this.outletSelectedB.rdcode.toString();
      // this.atobtransfer.FromVisiCooler1 = this.outletSelectedA.visicooler;
      this.atobtransfer.FromVisiCooler1 =this.selectedOutletDetailAtobVisicoolerValue;
      this.atobtransfer.ToVisiCooler1 = this.outletSelectedB.visicooler;
      this.atobtransfer.Flag = "SAVE";
      this.atobtransfer.ID = 0;
      this.atobtransfer.MobileNo = this.visicColerForm.mobNo.toString();
      this.atobtransfer.Add1 = this.visicColerForm.address1.toString();
      this.atobtransfer.Add2 = this.visicColerForm.address2.toString();
      this.atobtransfer.Landmark = this.visicColerForm.landmark.toString();
      this.atobtransfer.PinCode = this.visicColerForm.pinCode.toString();
      this.atobtransfer.Visiid = this.outletSelectedA.visiID == null ? "0" : this.outletSelectedA.visiID.toString();
      this.atobtransfer.CoolerPhoto = this.atobtransferObj.CoolerPhoto == null ? '' : this.atobtransferObj.CoolerPhoto;
      this.atobtransfer.OutletPhoto = this.atobtransferObj.OutletPhoto == null ? '' : this.atobtransferObj.OutletPhoto;
      this.atobtransfer.OutletIDPhoto = this.atobtransferObj.OutletIDPhoto == null ? '' : this.atobtransferObj.OutletIDPhoto;
      this.atobtransfer.Page1Photo = this.atobtransferObj.Page1Photo == null ? '' : this.atobtransferObj.Page1Photo;
      this.atobtransfer.Page2Photo = this.atobtransferObj.Page2Photo == null ? '' : this.atobtransferObj.Page2Photo;
      this.atobtransfer.FIRID = this.outletSelectedA.firid == null ? 0 : this.outletSelectedA.firid;
      this.atobtransfer.EmailID = this.visicColerForm["EMailID"].toString();
      this.atobtransfer.AssetCode = this.visicColerForm["AssetCOde"].toString();
      this.atobtransfer.SerialNo = this.visicColerForm["SerialNo"].toString();
      this.atobtransfer.keyid = localStorage.getItem("keyID");
      this.atobtransfer.Outletname = this.outletSelectedB.outletname.toString();

      // new
      this.atobtransfer.RequestRaiseBy = "PWA";
      this.atobtransfer.Lat = this.lat?.toString();
      this.atobtransfer.Long = this.lng?.toString()
    }

    //New
    this.mds.MA_SaveAToBTransfer_V1(this.atobtransfer, this.token, this.device, this.deviceType, 'A to B Transfer Preview').subscribe(res => {
      debugger;
      console.log("saveAtoBtransfer", res)
      this.router.navigate(['/home/transactions'], { queryParams: { from: 'AtoB' } });
    });


    //old
    // this.mds.saveAtoBtransfer(this.atobtransfer, this.token, this.device, this.deviceType, 'A to B Transfer Preview').subscribe(res => {
    //   debugger;
    //   console.log("saveAtoBtransfer", res)
    //   this.router.navigate(['/home/transactions'], { queryParams: { from: 'AtoB' } });
    // });
  }

  searchOutlet(OutletUID: string, Status: string) {
    let type = "fora";
    this.mds.getAToBTransferOutletDetails(OutletUID, this.userId, type, Status, this.token, this.device, this.deviceType, 'A to B Transfer Preview').subscribe(res => {
      this.list = res;
    });
  }

  AToBImagesDownload(filename: any) {
    this.ds.getDownloadFileFromS3Bucket("ATOB", filename).subscribe((res) => {
      this.createImageFromBlob(res.body, filename)
    }, (error) => { console.log("error", error) });
  }

  createImageFromBlob(image: Blob, filename: any) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      debugger
      // filename.substring(filename.length - 6)
      // 
      switch (filename.split("_").at(-1)) {
        case '1.jpg':
          this.coolerPhoto = reader.result;
          break;
        case '2.jpg':
          this.outletPhoto = reader.result;
          break;
        case '3.jpg':
          this.outletIDPhoto = reader.result;
          break;
        case '7.jpg':
          this.pagePhoto1 = reader.result;
          break;
        case '8.jpg':
          this.pagePhoto2 = reader.result;
          break;
        case '1.JPEG':
          this.coolerPhoto = reader.result;
          break;
        case '2.JPEG':
          this.outletPhoto = reader.result;
          break;
        case '3.JPEG':
          this.outletIDPhoto = reader.result;
          break;
        case '7.JPEG':
          this.pagePhoto1 = reader.result;
          break;
        case '8.JPEG':
          this.pagePhoto2 = reader.result;
          break;
      }
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

}
