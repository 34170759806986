import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Enclass } from '../models/encryption-class';
import { AtoBtransfer, ApproveAtoBTransfer, QRCodeData, SaveFirReason, InputPara } from '../models/kpi-models';
import { statusreport } from 'src/app/models/kpi-models';
import { environment } from 'src/environments/environment';
import { DataService } from './data.service';
@Injectable({
  providedIn: 'root'
})
export class MongoDataService {
  // public url = 'https://devsalespulseapi.cilsales.net/api/';
  //  public url = 'https://devsalespulseapi.cilsales.net/api/'; 
  // public url = 'https://localhost:44368/api/';
  // public url='https://salespulseapi.cilpwa.net/api/';
  //  public url = 'https://testapi1.cilsales.net/api/';
  // public url = 'https://devmeraapp.cilsales.net/#/';
  public url = environment.apiUrl;

  public subUrl = "";
  constructor(private http: HttpClient) {
  }
  /** POST DATA TO SERVER */
  getSecondaryTrend(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    console.log("httpOptions", httpOptions);
    console.log("this.url ",this.url )
    return this.http.post<any>(this.url + "Mongo/GetSecondaryTrend/", input, httpOptions);
  }

  getTargetAchivementValue(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    console.log("httpOptions", httpOptions);
    return this.http.post<any>(this.url + "Mongo/GetTargetAchivementValue/", input, httpOptions);
  }

  getPCtsiincentive(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    console.log("httpOptions", httpOptions);
    return this.http.post<any>(this.url + "Mongo/GetPCtsiincentive/", input, httpOptions);
  }

  getCommonPerformance(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    if (input.TagName == 'cat' || input.TagName == 'townclass') {
      this.subUrl = 'GetCattownperformance';
    } else {
      this.subUrl = 'GetChannelPerformance';
    }
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'Mongo/' + this.subUrl + '/', input, httpOptions);
  }
  getGrowKPIDashboard(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetGrowKPI/", input, httpOptions);
  }

  getVPOORVISIScanDashboard(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetVPOORVISIScan/", input, httpOptions);
  }

  getProgramtargetDetailsDashboard(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'Mongo/GetProgramtargetDetails/', input, httpOptions);
  }
  getProgramtargetDashboard(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'Mongo/GetProgramtarget/', input, httpOptions);
  }

  GetMenuData(input: InputPara): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    console.log("httpOptions", httpOptions);
    return this.http.post<any>(this.url + "Mongo/GetMenuData/", input, httpOptions);
  }
  getGrowKPI(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    console.log("httpOptions", httpOptions);
    return this.http.post<any>(this.url + "Mongo/GetGrowKPI/", input, httpOptions);
  }

  geturjaaVCScan(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    console.log("httpOptions", httpOptions);
    return this.http.post<any>(this.url + "Mongo/GetUrjaavcscan/", input, httpOptions);
  }
  getVPOORVISIScan(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    console.log("httpOptions", httpOptions);
    return this.http.post<any>(this.url + "Mongo/GetVPOORVISIScan/", input, httpOptions);
  }
  getNewRLADashboard(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetNewRLA/", input, httpOptions);
  }
  getUlbDashboard(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetUlb/", input, httpOptions);
  }
  getOverallUlbDashboard(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetOverallUlb/", input, httpOptions);
  }


  getMScoreDashboard(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,

      })
    }; debugger;
    return this.http.post<any>(this.url + "Mongo/GetMScoreData/", input, httpOptions);
  }
  GetMScoreDataPCDashboard(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,

      })
    };
    return this.http.post<any>(this.url + "Mongo/GetMScoreDataPC/", input, httpOptions);
  }
  GetMScoreDataOutletDashboard(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,

      })
    };
    return this.http.post<any>(this.url + "Mongo/GetMScoreDataOutlet/", input, httpOptions);
  }


  // MaATS/GetEligibleOutletList/UserID/RequirementType/ID/UserType
  // ATS_GetPendingPulloutList(UserID: string, UserType: string, PulloutNo: number): Observable<any> {
  //   return this.http.get<any>(this.url + 'ATS/ATS_GetPendingPulloutList/' + UserID + '/' + UserType + '/' + PulloutNo, this.httpOptions);
  // }

  // pullout transaction screen
  getPendingPulloutList(UserID: string, token: string, UserType: any, PulloutNo: number, device: any, deviceType: any, FormName: any): Observable<any> {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MaATS/ATS_GetPendingPulloutList/' + UserID + '/' + UserType + '/' + PulloutNo, httpOptions);
  }

  // getOutletDetailsByID(OutletCode: string, token: string, device: any, deviceType: any): Observable<any> {

  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': '*',
  // "Authorization": "Bearer " + token,
  //       'DeviceID': device,
  //       'DeviceType': deviceType,
  //     })
  //   };
  //   return this.http.get<any>(this.url + 'MaATS/GetOutletDetailsByID/' + OutletCode, httpOptions);
  // }

  getOutletDetailsPullout(OutletCode: string, token: string, UserID: string, Status: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MaATS/GetOutletDetailsPullout/' + OutletCode + '/' + UserID + '/' + Status, httpOptions);
  }

  getReasons(token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MaATS/GetReasons', httpOptions);
  }

  savePullRequest(pulloutEntry: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MaATS/SavePulloutRequest/', pulloutEntry, httpOptions);
  }

  approvePullRequest(pullRequest: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MaATS/ApprovePullRequest/', pullRequest, httpOptions);
  }
  getNewRLAdata(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetNewRLA/", input, httpOptions);
  }


  getUnbilledOutlet(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetUnbilledoutlet/", input, httpOptions);

  }
  getPCAttendancedata(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetAttendance/", input, httpOptions);
  }

  //GetRdList/{FieldName}/{FieldValue}/{UserID}
  getRdList(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    console.log("httpOptions", httpOptions);
    return this.http.post<any>(this.url + "Mongo/GetRdList/", input, httpOptions);
  }

  getP7payout(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'Mongo/GetP7payout/', input, httpOptions);
  }

  getNpd(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'Mongo/GetNpd/', input, httpOptions);
  }

  //GetRdInformation/6910001/prachi
  getRdInformation(RDCode: any, token: string, UserID: any, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + "Mongo/GetRdInformation/" + RDCode + '/' + UserID, httpOptions);
  }
  getEarningTrend(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetEarningTrend/", input, httpOptions);
  }
  //https://localhost:44319/api/Mongo/GetGrowthOverLYSM/rd/7092744/0/prachi
  getGrowthOverLYSM(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetGrowthOverLYSM/", input, httpOptions);
  }
  //[HttpGet("GetSEEarning/{​FieldName}​/{​FieldValue}​/{​tagname}​/{​datatype}​/{​UserID}​")]
  GetSEEarningData(input: InputPara, token: string, device: any, deviceType: any, FormName: any) {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetSEEarning/", input, httpOptions);
  }

  getAToBTransferOutletDetails(OutletCode: string, UserID: string, Type: string, Status: string, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MaATS/ATS_GetAToBTransferOutletDetails/' + OutletCode + '/' + UserID + '/' + Type + '/' + Status, httpOptions);
  }

  saveAtoBtransfer(atobtransfer: AtoBtransfer, token: string, device: any, deviceType: any, FormName: any): Observable<AtoBtransfer> {
    debugger;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<AtoBtransfer>(this.url + 'MaATS/ATS_SaveAtoBtransfer/', atobtransfer, httpOptions);
  }

  ATS_GetAtoBApprovalDataForSO(request:any, token: string, device: any, deviceType: any, FormName: any): Observable<AtoBtransfer> {
    debugger;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MaATS/ATS_GetAtoBApprovalDataForSO/', request, httpOptions);
  }

  MA_SaveAToBTransfer_V1(request: any, token: string, device: any, deviceType: any, FormName: any): Observable<AtoBtransfer> {
    debugger;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MaATS/MA_SaveAToBTransfer_V1/', request, httpOptions);
  }

  getATOBTransferList(UserID: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MaAtS/MA_GetATOBTransferList/' + UserID, httpOptions);
  }

  getAtoBApprovalData(UserID: string, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MaATS/GetAtoBApprovalData/' + UserID, httpOptions);
  }
  //https://localhost:44319/api/Mongo/GetUnbilledoutletDetails/ALLINDIA/ALLINDI0/0/prachi

  getUnbilledoutletDetails(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetUnbilledoutletDetails/", input, httpOptions);
  }

  approveAtoBtransfer(approveAtoBTransfer: ApproveAtoBTransfer, token: string, device: any, deviceType: any, FormName: any): Observable<ApproveAtoBTransfer> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<ApproveAtoBTransfer>(this.url + 'ATS/ApproveAtoBtransfer/', approveAtoBTransfer, httpOptions);
  }

  getGreenOutlets(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetGreenOutlets/", input, httpOptions);
  }
  //https://localhost:44319/api/Mongo/GetTodaysOrder/rd/7102708/prachi
  getTodaysOrder(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetTodaysOrder/", input, httpOptions);
  }

  getUserDetails(UserID: string, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        // "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
      })
    };
    return this.http.get<any>(this.url + 'MaATS/GetUserDetails/' + UserID, httpOptions);
  }

  firlist(UserID: any, KeyId: any, Outletcode: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MaATS/FIRlist/' + UserID + '/' + KeyId + '/' + Outletcode, httpOptions);
  }

  saveQRCodeScanningData(qrCodeData: QRCodeData, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MaATS/SaveQRCodeScanningData/', qrCodeData, httpOptions);
  }

  saveFIRRequest(saveFirReason: SaveFirReason, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MaATS/SaveFirReason/', saveFirReason, httpOptions);
  }

  GetMarketData(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'Mongo/Getpcmarketdata/', input, httpOptions);
  }
  //GET TSI ACTIVE MARKET DATA
  GetTSIMarketData(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'Mongo/GetTsimarketdata/', input, httpOptions);
  }
  //GET PC OutletList for Active MARKET DATA
  GetPcOutletList(rdcode: number, pcuid: number, UserID: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'Mongo/GetPcOutletList/' + rdcode + '/' + pcuid + '/' + UserID, httpOptions);
  }
  //GET PC OutletList for Active MARKET DATA
  GetTSIOutletList(pcuid: number, UserID: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'Mongo/GetTSIOutletList/' + pcuid + '/' + UserID, httpOptions);
  }

  //https://localhost:44319/api/Mongo/GetRdgrow/7119576/Prachi
  GetRDGrow(RDCode: any, UserID: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'Mongo/GetRdgrow/' + RDCode + '/' + UserID, httpOptions);
  }

  //GetMadates
  GetMadates(): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',

      })
    };
    return this.http.get<any>(this.url + 'Mongo/GetMadates/', httpOptions);
  }

  getUsers(userID: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'Mongo/GetUsers/' + userID, httpOptions);
  }

  getcomplaint(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'Mongo/Getcomplaint/', input, httpOptions);
  }
  GetVMSCallDetails(OutletCode: any, userId: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    //     [7:01 PM] Raghunath Nerurkar

    //https://localhost:44319/api/Mongo/MAATS_GetOutletDetail/2008362/COMPLAINTS

    return this.http.get<any>(this.url + 'Mongo/MAATS_GetOutletDetail/' + OutletCode + '/' + userId, httpOptions);
  }

  getOutletDetailByRDWise(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'Mongo/GetOutletDetailByRDWise/', input, httpOptions);
  }

  
  GetOutletDetailByRDWise_New(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'Mongo/GetOutletDetailByRDWise_New/', input, httpOptions);
  }

  getStatemaster(tagName: any, branchName: any, UserID: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'Mongo/GetStatemaster/' + tagName + '/' + branchName + '/' + UserID, httpOptions);
  }

  //https://localhost:44319/api/Mongo/GetFAQ/santosh
  getFAQ(UserID: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'Mongo/GetFAQ/' + UserID, httpOptions);
  }

  getVillagedata(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetVillagedata", input, httpOptions);
  }

  getDMSData(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetDMSData", input, httpOptions);
  }

  getPCList(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetPCList", input, httpOptions);
  }

  getAutoCloserOutletForSO(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetAutoCloserOutlet", input, httpOptions);
  }

  getReasonListForSO(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetReasonList", input, httpOptions);
  }
  GetHealthStatusData(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetHealthStatusData", input, httpOptions);
  }
  GetReasonList(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetReasonList", input, httpOptions);
  }
  UpdateHealthStatusData(input: Array<statusreport>, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/UpdateStatusReport", input, httpOptions);
  }
  GetHealthStatusAbt(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetHealthStatusAbt", input, httpOptions);
  }

  getHealthReport(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetHealthReport", input, httpOptions);
  }

  getpermission(userID: any, report: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'Mongo/Getpermission/' + userID + '/' + report, httpOptions);
  }

  updateFeedback(DataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/UpdateFeedback", DataToSend, httpOptions);
  }

  getProductivity(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetProductivity", input, httpOptions);
  }
  Getordermaster(token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MaATS/Getordermaster', httpOptions);
  }
  GetGrowKPIUrja(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    console.log("httpOptions", httpOptions);
    return this.http.post<any>(this.url + "Mongo/GetGrowKPIUrja/", input, httpOptions);
  }

  GetordermasterForErods(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/Getordermaster", input, httpOptions);
  }


  GetUniversalMappingdata(input: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetUniversalMappingdata", input, httpOptions);
  }


  
  MA_GetUniversalMappingDataByUid(input: any,clientId:any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
        'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + "MeraApp2/MA_GetUniversalMappingDataByUid", input, httpOptions);
  }

  
  GetOutletMasterDetails(input: any, token: string, device: any, deviceType: any, FormName: any,clientId:any): Observable<any> {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetOutletMasterDetails", input, httpOptions);
  }

  HD_urgencyImpactData_Bind(input: any, token: string, device: any, deviceType: any, FormName: any,clientId:any): Observable<any> {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + "HelpDesk/HD_urgencyImpactData_Bind", input, httpOptions);
  }

  HD_UserMasterData(input: any, token: string, device: any, deviceType: any, FormName: any,clientId:any): Observable<any> {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + "HelpDesk/HD_UserMasterData", input, httpOptions);
  }

  Insert_TicketDetails(input: any, token: string, device: any, deviceType: any, FormName: any,clientId:any): Observable<any> {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + "HelpDesk/Insert_TicketDetails", input, httpOptions);
  }


  HD_UpdateTicketDetailsAddComment(input: any, token: string, device: any, deviceType: any, FormName: any,clientId:any): Observable<any> {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + "HelpDesk/HD_UpdateTicketDetailsAddComment", input, httpOptions);
  }

  HD_GetFeedBackOptions(input: any, token: string, device: any, deviceType: any, FormName: any,clientId:any): Observable<any> {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + "HelpDesk/HD_GetFeedBackOptions", input, httpOptions);
  }

  HD_FeedBackFlagChange(input: any, token: string, device: any, deviceType: any, FormName: any,clientId:any): Observable<any> {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + "HelpDesk/HD_FeedBackFlagChange", input, httpOptions);
  }

  HD_UpdateFeedBackStatus(input: any, token: string, device: any, deviceType: any, FormName: any,clientId:any): Observable<any> {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + "HelpDesk/HD_UpdateFeedBackStatus", input, httpOptions);
  }

  HD_GetAllTicketDetails(input: any, token: string, device: any, deviceType: any, FormName: any,clientId:any): Observable<any> {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + "HelpDesk/HD_GetAllTicketDetails", input, httpOptions);
  }

  HD_ModuleCategoryData_Bind(input: any, token: string, device: any, deviceType: any, FormName: any,clientId:any): Observable<any> {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + "HelpDesk/HD_ModuleCategoryData_Bind", input, httpOptions);
  }


  MA_getQRCodeDetails(input: any, token: string, device: any, deviceType: any, FormName: any,clientId:any): Observable<any> {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + "Meraapp2/MA_getQRCodeDetails", input, httpOptions);
  }

  MA_GetDataForApproval(input: any, token: string, device: any, deviceType: any, FormName: any,clientId:any): Observable<any> {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + "MeraApp2/MA_GetDataForApproval", input, httpOptions);
  }
  
  MA_OutletNameChangeApproval(input: any, token: string, device: any, deviceType: any, FormName: any,clientId:any): Observable<any> {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + "MeraApp2/MA_OutletNameChangeApproval", input, httpOptions);
  }

  MA_CheckOutletNameChangeStatus(input: any, token: string, device: any, deviceType: any, FormName: any,clientId:any): Observable<any> {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + "MeraApp2/MA_CheckOutletNameChangeStatus", input, httpOptions);
  }

  MA_SetOutletNameChangeDetails(input: any, token: string, device: any, deviceType: any, FormName: any,clientId:any): Observable<any> {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + "MeraApp2/MA_SetOutletNameChangeDetails", input, httpOptions);
  }

  MA_OutletNameChangeVerifyOTP(input: any, token: string, device: any, deviceType: any, FormName: any,clientId:any): Observable<any> {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        // 'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + "MeraApp2/MA_OutletNameChangeVerifyOTP", input, httpOptions);
  }

  
  MA_CheckOTPISSubmit(input: any, token: string, device: any, deviceType: any, FormName: any,clientId:any): Observable<any> {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        // 'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + "MeraApp2/MA_CheckOTPISSubmit", input, httpOptions);
  }

  isvalidqrcode(request: any, token: string, UserType: any, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'Mongo/isvalidqrcode/', request, httpOptions);
  }

  MarketWorking_GetRdList(request: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'mongo/MarketWorking_GetRdList/', request, httpOptions);
  }

  PushNotification(request: any, device: any, deviceType: any, FormName: any,clientId:any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
        'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + 'PushNotification/PushNotification/', request, httpOptions);
  }

  
  HD_NotificationSave(request: any, device: any, deviceType: any, FormName: any,clientId:any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
        'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + 'HelpDesk/HD_NotificationSave/', request, httpOptions);
  }


  MarketWorking_GetPCOutletCount(request: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'mongo/MarketWorking_GetPCOutletCount/', request, httpOptions);
  }


  MarketWorking_GetBeatTargetData(request: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'mongo/MarketWorking_GetBeatTargetData/', request, httpOptions);
  }

  MarketWorking_GetUnbilledOutletData(request: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'mongo/MarketWorking_GetUnbilledOutletData/', request, httpOptions);
  }

  MarketWorking_GetOutletSalesParameter(request: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'mongo/MarketWorking_GetOutletSalesParameter/', request, httpOptions);
  }


  MarketWorking_GetRDNonSalesParameter(request: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'mongo/MarketWorking_GetRDNonSalesParameter/', request, httpOptions);
  }

  MarketWorking_GetGreenOutletData(request: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'mongo/MarketWorking_GetGreenOutletData/', request, httpOptions);
  }

  MarketWorking_GetOutletData(request: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'mongo/MarketWorking_GetOutletData/', request, httpOptions);
  }

  MarketWorking_GetOutletKPI(request: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'mongo/MarketWorking_GetOutletKPI/', request, httpOptions);
  }

  MarketWorking_GetOutletListData(request: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'mongo/MarketWorking_GetOutletListData/', request, httpOptions);
  }

  MarketWorking_GetGrowthOverLYSM(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/MarketWorking_GetGrowthOverLYSM/", input, httpOptions);
  }

  MarketWorking_GetPCIncentiveData(input: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "Mongo/MarketWorking_GetPCIncentiveData/", input, httpOptions);
  }

  
  HD_TicketNotification(input: any,clientId:any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'FormName': FormName,
        'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + "HelpDesk/HD_TicketNotification/", input, httpOptions);
  }

  

  GetTodaysVisited(input: any,clientId:any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'FormName': FormName,
        'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + "Mongo/GetTodaysVisited/", input, httpOptions);
  }
}


