import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DatePipe } from '@angular/common';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-outlet-detail-atob',
  templateUrl: './outlet-detail-atob.component.html',
  styleUrls: ['./outlet-detail-atob.component.css']
})
export class OutletDetailAtobComponent implements OnInit {

  deviceType: any;
  device: any;
  isLongPress: boolean = false;
  isSelected: boolean = false;
  outletSelectedA: any;
  isCheck: boolean = false;
  isCheckvisi2: boolean = false;
  selected: string = '';
  outLetDetails: any;
  address: any;
  formname: any;
  actiondesc: any;
  UserType: any;
  fieldName: any;
  fieldValue: any;
  userId: any;
  selectedOutletDetailAtobVisicoolerValue:any=""
  constructor(private activatedRoute: ActivatedRoute, private cs: CommonUtilitiesService, private router: Router, private headerService: HeaderService, private datePipe: DatePipe, private mds: MongoDataService,private toaster:ToastrService) { }

  ngOnInit(): void {
    this.headerService.setTitle('Outlet Detail');
    this.headerService.setActiveURl('/outletDetailAtob');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.outletSelectedA = JSON.parse(localStorage.getItem("outletSelectedA") || '{}');
    this.userId = localStorage.getItem("UserID");
    this.UserType = localStorage.getItem('UserType');
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    console.log("outletSelectedA", this.outletSelectedA);
    this.selected = this.activatedRoute.snapshot.queryParams["selected"];
    console.log("selected", this.selected);
    if (this.selected == 'VS') {
      this.outLetDetails = JSON.parse(localStorage.getItem("outLetDetails") || '{}');
      console.log("outLetSelected", this.outLetDetails);
      this.address = this.outLetDetails[0].address1;
    }

    this.formname = "outletDetailAtob";
    this.actiondesc = "Outlet Detail Atob";
    this.cs.GetGeoLocation(this.userId, this.UserType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);

  }

  onLongPress() {
    this.isLongPress = true;
  }

  clickOnSelect() {
    this.isSelected = true;
  }

  rowClick() {
    debugger
    if((this.selectedOutletDetailAtobVisicoolerValue?.toLowerCase()== 'none'  || this.selectedOutletDetailAtobVisicoolerValue?.toLowerCase()== '')){
      this.toaster.warning("You can not Transfer None/OwnAC/blank visicooler")
      return;
    }

    if (this.selected == 'VS') {
      this.router.navigate(['/home/toOutletAtob'], { queryParams: { selected: this.selected } });
    } else {
      this.router.navigate(['/home/toOutletAtob']);
    }
    localStorage.setItem("selectedOutletDetailAtobVisicoolerValue",this.selectedOutletDetailAtobVisicoolerValue)
  }

  selected_Reason(e: any) {
    debugger
    this.selectedOutletDetailAtobVisicoolerValue=""
    if (e.target.checked) {
      this.isCheck = e.target.value;
      this.selectedOutletDetailAtobVisicoolerValue=this.outletSelectedA.visicooler
    } else {
      this.isCheck = false;
    }
  }
  selected_Reasonvisi2(e: any) {
    debugger
    this.selectedOutletDetailAtobVisicoolerValue=""
    if (this.outletSelectedA[0]?.visicooler2.toUpperCase() != "NONE") {
      if (e.target.checked) {
        this.isCheckvisi2 = e.target.value;
        this.selectedOutletDetailAtobVisicoolerValue=this.outletSelectedA.visicooler2
      } else {
        this.isCheckvisi2 = false;
      }
    }
  }
}
