<div class="row mt-4 mb-3">
    <div class="col-8">
        <input type="text" class="UID" #UID placeholder="Search UID" (keypress)="numberOnly($event)" />
    </div>
    <div class="col-4">
        <button class="btn btn_GO" (click)="GOBtn(UID)">GO</button>
    </div>
</div>


<div class="row mt-5">
    <div class="col-12">
        <span class="outlet_Name">{{filteredList[0]?.Outletname | titlecase}}</span>
        <span class="outlet_Code ml-1">{{filteredList[0]?.Outletcode}}</span>
    </div>
</div>

<div class="row mt-2">
    <div class="col-12">
        <span class="rd_Name">{{filteredList[0]?.Rdname | titlecase}}</span>
        <span class="rd_Code ml-1">{{filteredList[0]?.Rdcode}}</span>
    </div>
</div>

<div class="" *ngIf="filteredList[0]?.ISLatestDtls=='Y'">

    <div class="row mt-2">
        <div class="col-12">
            <span class="rd_Name">Latest Survey Date :</span>
            <span class="rd_Code ml-1">{{filteredList[0]?.NewSurveydate}}</span>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-12">
            <span class="rd_Name">Survey Status :</span>
            <span class="rd_Code ml-1"> {{filteredList[0]?.NewParalleldotsstatus}}</span>
        </div>
    </div>

    <div class="row mt-2 ">
        <div class="col-12 rd_Name">Latest Outlet Photo</div>
    </div>
    <div class="row mt-1">
        <div class="col-12">
            <img class="img_fluid mt-2 surveyImg"
                [src]="mds.url + 'MeraNet/GetDownloadFileFromS3Bucket/SurveyImages/' + filteredList[0]?.NewImagename">
        </div>
    </div>
</div>

<hr *ngIf="filteredList[0]?.ISLatestDtls=='Y'">

<div class="" *ngIf="filteredList[0]?.Outletname!=null">
    <div class="row mt-2">
        <div class="col-12">
            <span class="rd_Name">Previous Survey Date :</span>
            <span class="rd_Code ml-1">{{filteredList[0]?.PrevSurveydate}}</span>
        </div>
    </div>
    

    <div class="row mt-2">
        <div class="col-12">
            <span class="rd_Name">Parallel Dot Status :</span>
            <span class="rd_Code ml-1"> {{filteredList[0]?.PrevParalleldotsstatus}}</span>
        </div>
    </div>
    <div class="row mt-2 " *ngIf="filteredList[0]?.PrevParalleldotsstatus.toLowerCase()=='reject'">
        <div class="col-12">
            <span class="rd_Name">Reason Of Rejection :</span>
            <span class="rd_Code ml-1 " style="word-wrap: break-word;">
                {{filteredList[0]?.PrevRejectionreason}}
            </span>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-12">
            <span class="rd_Name">RD Status :</span>
            <span class="rd_Code ml-1"> {{filteredList[0]?.Rdstatus}}</span>
        </div>
    </div>

    <div class="row mt-2" *ngIf="filteredList[0]?.Rdstatus?.toLowerCase()=='approved'">
        <div class="col-12">
            <span class="rd_Name">RD Approval Date :</span>
            <span class="rd_Code ml-1"> {{filteredList[0]?.RDApprovalDate}}</span>
        </div>
    </div>

    <div class="row mt-2 ">
        <div class="col-12 rd_Name">Photo Of Outlet</div>
    </div>
    <div class="row mt-1">
        <div class="col-12">
            <img class="img_fluid mt-2 surveyImg"
                [src]="mds.url + 'MeraNet/GetDownloadFileFromS3Bucket/SurveyImages/' + filteredList[0]?.PrevImagename">
        </div>
    </div>
</div>

<br>
<br>
<br>
<br>
<br>