<div>
    <form [formGroup]="ticketLogForm" autocomplete="off">
        <div class="row" style="margin-top: 2rem;">
            <div class="col-12">
                <div class="form-group">
                    <label for="outlet_Name" class="label_Text">Subject</label>
                    <span class="ms-1" style="color: red;">*</span>
                    <input id="outlet_Name" class="form-control" type="text" placeholder="Enter the subject"
                        name="Subject" formControlName="Subject" required  minlength="15" maxlength="50"
                        (keypress)="subjectKeyPreessEventOnly($event)" (paste)="subjectPreventPaste($event)">
                </div>

            </div>
            <div class="col-12">
                <div class="form-group">
                    <label for="rd" class="label_Text">Module</label>
                    <span class="ms-1" style="color: red;">*</span>
                    <select id="Module" class="form-select" name="Module" formControlName="Module"
                        aria-placeholder="Select Module Name" (change)="onChangeModule($event)" required>
                        <option value="" selected disabled>Select Option</option>
                        <option value="" *ngFor="let obj of moduleList" [value]="obj">
                            {{obj}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label for="mobile_No" class="label_Text">Category</label>
                    <span class="ms-1" style="color: red;">*</span>
                    <select id="Category" class="form-select" name="Category" formControlName="Category"
                        (change)="onChangeCategory($event)" required>
                        <option value="" selected disabled>Select Category</option>
                        <option *ngFor="let obj of categoyList" [value]="obj.category">
                            {{obj.category}}
                        </option>
                    </select>
                </div>

            </div>
            <div class="col-12">
                <div class="form-group">
                    <label for="contact_Person" class="label_Text">Ticket For</label>
                    <span class="ms-1" style="color: red;">*</span>
                    <select id="TicketFor" class="form-select" name="TicketFor" formControlName="TicketFor" required
                        placeholder="Select Ticket For">
                        <option value="" selected disabled>Select Ticket For</option>
                        <option *ngFor="let obj of ticketFor" [value]="obj">
                            {{obj}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-12" *ngIf="ticketLogForm.controls['TicketFor'].value?.toLowerCase()=='others ticket'">
                <div class="form-group">
                    <label for="address" class="label_Text">Contact Person</label>
                    <span class="ms-1" style="color: red;">*</span>
                    <input id="PersonName" class="form-control" placeholder="Enter Contact Person Name" type="text"
                        name="PersonName" formControlName="PersonName">
                </div>
            </div>
            <div class="col-12" *ngIf="ticketLogForm.controls['TicketFor'].value?.toLowerCase()=='others ticket'">
                <div class="form-group">
                    <label for="Visicooler" class="label_Text">Contact Person Mobile Number</label>
                    <span class="ms-1" style="color: red;">*</span>
                    <input id="mobile_No" class="form-control" placeholder="Enter Contact Person Mobile Number"
                        type="tel" name="mobNo" formControlName="mobNo" maxlength="10" (keypress)="numberOnly($event)"
                        (paste)="preventPaste($event)" appNumericOnly>
                </div>
            </div>

            <div class="col-12">
                <div class="form-group">
                    <label for="State" class="label_Text">Urgency</label>
                    <span class="ms-1" style="color: red;">*</span>
                    <button class="float-end p-0" mat-raised-button (click)="openTooltip()" #tooltip="matTooltip"
                        matTooltip="Based on your urgency type you have to select the urgency."
                        matTooltipPosition="above">
                        <img src="../../../assets/icons/information_btn.svg">
                    </button>
                    <select id="Urgency" class="form-select" name="Urgency" aria-placeholder="Select Urgency"
                        formControlName="Urgency" (change)="onChangeUrgency($event)" required>
                        <option value="" selected disabled>Select Urgency</option>
                        <option *ngFor="let obj of urgencyList" [value]="obj">
                            {{obj}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="col-12">
                <div class="form-group">
                    <label for="VC Make" class="label_Text">Impact</label>
                    <span class="ms-1" style="color: red;">*</span>
                    <button class="float-end p-0" mat-raised-button (click)="openImpactTooltip()"
                        #impacttooltip="matTooltip"
                        matTooltip="Based on how many people are impacted, you have to select the impacted."
                        matTooltipPosition="above">
                        <img src="../../../assets/icons/information_btn.svg">
                    </button>
                    <select id="Impact" class="form-control" aria-placeholder="Enter Impact" name="Impact"
                        formControlName="Impact" (change)="onChangeImpact($event)" required>
                        <option value="" selected disabled>Select Impact</option>
                        <option *ngFor="let obj of impactList" [value]="obj">
                            {{obj}}
                        </option>
                    </select>
                </div>
            </div>


            <div class="col-12" *ngIf="isWatchlisShow">
                <div class="form-group">
                    <label for="State" class="label_Text">Watchlist : </label>
                    <span style="opacity: 0.5;font-size: 13px;"> {{selectedWatchlistItems[0]?.userName}}</span>
                    <span *ngIf="selectedWatchlistItems?.length>0" style="opacity: 0.5;font-size: 13px;">
                        +{{selectedWatchlistItems?.length-1}}</span>
                    <app-select-multiselect-dropdown [dataSet]="watchListItems" [selectAreaId]="selectedAreaId"
                        [placeholder]="'Search WatchList'" (returnSelectedList)="receivedSelectedList($event)"
                        class="dmappelect-multicolumn-multiselect-table">
                    </app-select-multiselect-dropdown>
                </div>
            </div>


            <div class="col-12">
                <div class="form-group">
                    <label for="State" class="label_Text">Description</label>
                    <span class="ms-1" style="color: red;">*</span>
                    <!-- <input id="exampleFormControlTextarea1" class="form-control"
                        placeholder="Description of the problem/request" type="text" name="Description"
                        formControlName="Description" required maxlength="500"> -->

                    <textarea id="exampleFormControlTextarea1" class="form-control"
                        placeholder="Description of the problem/request" type="text" name="Description"
                        formControlName="Description" required maxlength="500" rows="4">
                        </textarea>
                </div>
            </div>

            <div class="col-12 mt-2">
                <div class="form-group">
                    <label for="State" class="label_Text">Attachment</label>

                    <button type="button" class="bg-transparent attachmentBtn float-end" (click)="fileInput.click()">
                        <img src="../../../assets/icons/attachment_btn.svg" class="mt-2">
                        <br>
                        <img [src]="" class="img-fluid" />
                    </button>

                    <input type="file" #fileInput (change)="fileProgress($event)" multiple
                        accept=".zip,.doc,.docx,.txt,.xlsx,.7z,.pdf,.jpg,.pptx,.msg,.png" id="attachSupportingDocument1"
                        style="display: none;" />

                    <div class="row w-100" *ngIf="isImageUploaded">
                        <div class="col-3" *ngFor="let previewUrl of fileList; let i = index">
                            <!-- <div type="file" class="row mt-2 ms-1 fileName" style="word-wrap: break-word;">
                                <span class="icon p-0">
                                    <img src="../../../assets/icons/closeAttachment.svg" class="float-end"
                                        (click)="removeAttachment(i)" />
                                    <img [src]="previewUrl" alt="Image preview" class="preview-img" />
                                </span>
                            </div> -->

                            <div type="file" class="row mt-2 ms-0 fileName" style="word-wrap: break-word;">
                                <span class="icon p-0" (click)="downloadFile(previewUrl)" style="max-height: 88px;
                                overflow: hidden;">
                                    <img src="../../../assets/icons/trash_icon.svg" class="float-end text-end"
                                        (click)="removeAttachment(i)" />

                                    <!-- src="../../../assets/icons/ImagesChat.svg" -->
                                    <img *ngIf='previewUrl?.split(".")?.pop()?.toLowerCase() == "jpg" || previewUrl?.split(".")?.pop()?.toLowerCase() == "jpeg" || previewUrl?.split(".")?.pop()?.toLowerCase() == "png"'
                                        [src]="url+'MeraNet/GetDownloadFileFromS3Bucket/HelpDesk/' + previewUrl"
                                        class="mb-1" style="height: 48px;
                                    margin-top: 4px;
                                    width: 56px;">

                                    <img *ngIf='previewUrl?.split(".")?.pop()?.toLowerCase() != "jpg" || previewUrl?.split(".")?.pop()?.toLowerCase() != "jpeg" || previewUrl?.split(".")?.pop()?.toLowerCase() != "png"'
                                        src="../../../assets/icons/ImagesChat.svg" class="mb-1"
                                        style="height: 48px;margin-top: 4px;width: 56px;">
                                    <!-- {{previewUrl}} -->
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <br>
    <br>
    <br>
    <br> <br><br><br><br>
    <br>

    <ng-template #openDPNARGModal let-modal>
        <div class="modal-header text-center">
            <h4 class="modal-title text_color_head text_20 text-center" id="modal-basic-title">
                <span class="ml-3 text-center Value_text">Select Media</span>
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="modalClose()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div id="accordion">
                <div class="card">
                    <div class="card-header" id="headingOne">
                        <div class="row">
                            <div class="col-2">
                                <img src="../../../assets/icons/gallary_icon.svg">
                            </div>
                            <div class="col-10">
                                <span class="attachment" (click)="gallary.click()">Gallary</span>
                                <input #gallary type="file" accept="image/*" id="gallaryInput" name="gallaryInput"
                                    (change)="onFileSelected($event)" style="display: none" />
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-2">
                                <img src="../../../assets/icons/camer_icon.svg">
                            </div>
                            <div class="col-10">
                                <span class="attachment text-center" (click)="camera.click()">Camera</span>
                                <input #camera type="file" accept="image/*" id="cameraInput" name="cameraInput"
                                    (change)="onFileSelected($event)" style="display: none" />
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-2">
                                <img src="../../../assets/icons/document_icon.svg">
                            </div>
                            <div class="col-10">
                                <span class="attachment text-center" (click)="excel.click()">Excel</span>
                                <input #excel type="file"
                                    accept=".csv,.xlsx,.xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    id="excelInput" name="excelInput" (change)="onFileSelected($event)"
                                    style="display: none" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #openExistingCommentImagesModal let-modal>
        <div class="modal-header text-center">
            <button type="button" class="close" aria-label="Close" (click)="modalClose()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div id="accordion">
                <div class="card">
                    <div class="card-header" id="headingOne">
                        <div class="row">
                            <div class="col-12">
                                <span style="font-weight: 500;
                                    letter-spacing: 0.21px;">
                                    <img [src]="url+'MeraNet/GetDownloadFileFromS3Bucket/HelpDesk/' + existingCommentImageName"
                                        alt="Image" class="img-fluid float-end">
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <!-- *ngIf="ticketLogForm.valid" -->
    <div class="footer">
        <div class="row text-center mt-3">
            <div class="col-12" (click)="raisedTicketClick()">
                RAISE TICKET
            </div>
        </div>
    </div>

</div>