import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DatePipe } from '@angular/common';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { InputPara } from 'src/app/models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-to-outlet-atob',
  templateUrl: './to-outlet-atob.component.html',
  styleUrls: ['./to-outlet-atob.component.css']
})
export class ToOutletAtobComponent implements OnInit {

  deviceType: any;
  device: any;
  searchText = '';
  isLongPress: boolean = false;
  isSelected: boolean = false;
  list: any;
  userType: any;
  userId: any;
  selected: string = '';
  fieldName: any;
  fieldValue: any;
  input: InputPara = new InputPara();
  token: any;
  formname: any;
  actiondesc: any;
  constructor(private activatedRoute: ActivatedRoute, private cs: CommonUtilitiesService, private router: Router, private headerService: HeaderService, private datePipe: DatePipe, private mds: MongoDataService, private notifyMeService: NotifyMeService,private toaster:ToastrService) { }

  ngOnInit(): void {
    this.headerService.setTitle('To Outlet');
    this.headerService.setActiveURl('/toOutletAtob');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userId = localStorage.getItem("UserID");
    this.userType = localStorage.getItem("UserType");
    this.token = localStorage.getItem("TokenNo");
    this.selected = this.activatedRoute.snapshot.queryParams["selected"];
    console.log("selected", this.selected);
    this.fieldName = localStorage.getItem("OriginalFieldName");
    this.fieldValue = localStorage.getItem("OriginalFieldValue");

    this.formname = "toOutletAtob"
    this.actiondesc = "To Outlet Atob";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
  }
  onLongPress() {
    this.isLongPress = true;
  }

  clickOnSelect() {
    this.isSelected = true;
  }

  rowClick(selected: any) {
    localStorage.setItem("outletSelectedB", JSON.stringify(selected));
    if (this.selected == 'VS') {
      this.router.navigate(['/home/outletDetailOneAtob'], { queryParams: { selected: this.selected } });
    } else {
      this.router.navigate(['/home/outletDetailOneAtob']);
    }
  }

  searchOutlet(OutletUID: string, Status: string) {
    debugger
    if (OutletUID != '') {
      this.input.FieldName = this.fieldName;
      this.input.FieldValue = this.fieldValue;
      this.input.TagName = OutletUID;
      this.input.Datatype = 'COMPLAINTS';
      this.input.UserID = this.userId;
      // this.mds.getOutletDetailByRDWise(this.input, this.token, this.device, this.deviceType,'From Outlet').subscribe(res => {
      //   console.log("getOutletDetailByRDWise", res);
      //   this.list = res;
      //   if(this.list.length == 0){
      //     this.notifyMeService.showWarning('Outlet Details not found for this Outlet Code', 'Sales Genie App');
      //   }
      // });}

      this.mds.GetOutletDetailByRDWise_New(this.input, this.token, this.device, this.deviceType, 'From Outlet').subscribe(res => {
        console.log("getOutletDetailByRDWise", res);
        this.list = res;
        if (this.list.length == 0) {
          this.notifyMeService.showWarning('The selected outlet is NOT verified. Please finish the outlet verification as per Company Policy', 'Sales Genie App');
        }
      });
    }
  }
}
