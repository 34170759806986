import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { ToastrService } from 'ngx-toastr';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { Enclass, ResultClass } from 'src/app/models/encryption-class';
import { EncryptionService } from 'src/app/service/encryption.service';


@Component({
  selector: 'app-survey-approval',
  templateUrl: './survey-approval.component.html',
  styleUrls: ['./survey-approval.component.css']
})
export class SurveyApprovalComponent implements OnInit {

  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  msg: any;
  private _searchText = '';
  searchText: any;
  token: any;
  FormName: any;
  filteredList: any = [];
  public UIDData: any = "";
  public liveUrl="https://salespulseapi.cilpwa.net/api/"
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;
  public enclass: Enclass = new Enclass();
  public resultClass: ResultClass = new ResultClass();
    secretKey:any=""
  clientId:any=""
  constructor(private headerService: HeaderService, private cs:CommonUtilitiesService,private router: Router, public ds: DataService, private toastr: ToastrService, public mds: MongoDataService,private es: EncryptionService) { }

  ngOnInit(): void {
    this.FormName = 'SurveyApproval'
    this.headerService.setTitle('Universe Re-Mapping');
    this.headerService.setActiveURl('/surveyApproval');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.secretKey = localStorage.getItem("SecreteKey")
    this.clientId = localStorage.getItem("ClientID");
    this.formname = "surveyApproval"
    this.actiondesc = "Universe Re-Mapping";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
  }

  

  GOBtn(UID: any) {
    debugger
    this.filteredList = ""
    if (UID.value == "") {
      this.toastr.warning("Please enter UID");
      this.filteredList = "";
      return;
    }

    let request ={
      "Outletcode": UID.value,
      "UserID":this.userId
    }

    this.es.Word = this.es.WordKey;
    this.es.encrypt(this.secretKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)

    this.mds.MA_GetUniversalMappingDataByUid(this.enclass,this.clientId, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {
      console.log("getSurveydetails", res)   
      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      let response = JSON.parse(this.es.decrypt(this.secretKey));

      this.filteredList = response;
      console.log("filteredList", this.filteredList)
      if (this.filteredList?.length==0) {
        this.toastr.warning("UID not found")
        return
      }

      if (this.filteredList[0]?.Outletname == null) {
        this.toastr.warning("UID not found")
      }


    });
  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
